
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/coming-soon",
      function () {
        return require("private-next-pages/coming-soon.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/coming-soon"])
      });
    }
  