import React from 'react'
import Metadata from '~/components/Metadata'
import { getAnnouncementBar, getPage } from 'graphql-cms/queries'
import nextContentWrapper from 'graphql-cms/nextContentWrapper'
import type { InferNextPage } from '~/types'
import { IHero } from 'graphql-cms/types'
import ResponsiveVideo from '~/components/Generic/Video'
import { useWindowSize } from '~/hooks'

export const getStaticProps = nextContentWrapper.getStaticProps(async () => {
  return {
    props: {
      page: await getPage({ slug: 'coming-soon' }),
      announcementBarConfig: await getAnnouncementBar(),
    },
  }
})

const ComingSoon: InferNextPage<typeof getStaticProps> = ({ page }) => {
  const { items: sections } = page.sectionsCollection ?? null
  const { items: assets } = sections[0].mediaCollection ?? null
  const asset = (assets[0] || {}) as IHero
  const { width } = useWindowSize()
  const { url } =
    width < 500 && asset?.imageMobile ? asset.imageMobile : asset.image

  return (
    <>
      <Metadata title={page.name} />
      <ResponsiveVideo sourceUrl={url} />
    </>
  )
}

export default ComingSoon
