/** @jsxImportSource theme-ui */
import React from 'react'
import { Box } from 'theme-ui'
import styles from './styles.module.css'

export interface ResponseVideoProps {
  sourceUrl: string
}

const ResponsiveVideo: React.FC<ResponseVideoProps> = ({ sourceUrl }) => {
  return (
    <Box className={styles.videoContainer}>
      {sourceUrl && (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: `
  <video style="position:fixed;width:100%;height:100%;object-fit:cover;z-index:0;" autoplay loop playsinline muted>
  <source src="${sourceUrl}" type="video/mp4"></source>
  Your browser does not support the video tag.
  </video>
  `,
            }}
          />
        </>
      )}
    </Box>
  )
}

export default ResponsiveVideo
